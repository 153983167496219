import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import { Screen } from 'styleguide/ScreenContainer';
import { ProfileHeader } from './components/ProfileHeader';
import { DisplayXS, TextLG, TextSMRegular } from 'styleguide/Texts';
import { ProfileBarChart } from './components/ProfileBarChart';
import { Card } from 'styleguide/Card';
import { View } from 'styleguide/View';
import { UserProfileViewModel } from './UserProfileViewModel';
import { WorkoutCell } from 'components/WorkoutCell/WorkoutCell';
import { LoadingCell } from 'screens/Feed/components/LoadingCell';
import { colors } from 'styleguide/colors';
import { Unauthenticated } from './components/Unauthenticated';
import { PrivateProfile } from './components/PrivateProfile';
import { ZeroWorkouts } from './components/ZeroWorkouts';
import useIsOnScreen from 'utils/useIsOnScreen';
import { EmptyGraph } from './components/EmptyGraph';
import { MobileRoutineCard, RoutinesCard } from './components/RoutinesCard';
import { Spacing } from 'styleguide/spacing';
import { secondsToWordFormatMinutes } from 'hevy-shared';
import { UserProfileResult } from './types';
import { FlexRow } from 'styleguide/Row';

const Content = styled.div`
  display: grid;
  max-width: 1024px;
  justify-content: center;
  margin-top: ${Spacing.lg}px;
  grid-template-columns: 5fr 3fr;
  gap: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const LeftContainer = styled(View)``;

const RightContainer = styled(View)`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileRoutinesContainer = styled(View)`
  max-width: calc(100vw - ${Spacing.md * 2}px);
  @media (min-width: 1024px) {
    display: none;
  }
  margin-bottom: ${Spacing.lg}px;
`;

const PagingLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${Spacing.md}px;
`;

interface UserProfileScreenProps {
  username: string;
  result: UserProfileResult;
}

export const UserProfileScreen = ({ username, result }: UserProfileScreenProps) => {
  const [vm, setVm] = useState(new UserProfileViewModel({ username, result }));

  const containerRef = useRef<HTMLDivElement | null>(null);
  const bottomOfWorkoutsRef = useRef<HTMLDivElement>(null);
  const isBottomVisible = useIsOnScreen(bottomOfWorkoutsRef);

  useEffect(() => {
    if (isBottomVisible) {
      vm.fetchMore();
    }
  }, [isBottomVisible]);

  useEffect(() => {
    vm.refreshContent();
  }, []);

  useEffect(() => {
    if (vm.username !== username) {
      const newVm = new UserProfileViewModel({ username, result });
      setVm(newVm);
      newVm.refreshContent();
      containerRef?.current?.scrollTo(0, 0);
    }
  }, [username]);

  return (
    <UserProfileScreenInner
      vm={vm}
      containerRef={containerRef}
      bottomOfWorkoutsRef={bottomOfWorkoutsRef}
    />
  );
};

interface UserProfileScreenInnerProps {
  vm: UserProfileViewModel;
  containerRef: React.Ref<HTMLDivElement>;
  bottomOfWorkoutsRef: React.Ref<HTMLDivElement>;
}

const renderProfileBody = ({
  vm,
  bottomOfWorkoutsRef,
}: {
  vm: UserProfileViewModel;
  bottomOfWorkoutsRef: React.Ref<HTMLDivElement>;
}) => {
  if (vm.profileResult.type === 'unauthenticated') {
    return <Unauthenticated username={vm.username} lastWorkout={vm.viewState.lastWorkout} />;
  } else if (!vm.isAuthorizedToViewProfile) {
    return <PrivateProfile username={vm.username} />;
  } else {
    return (
      <Content>
        <LeftContainer>
          {vm.numberOfWorkouts > 0 && (
            <Card style={{ padding: Spacing.lg, paddingLeft: 0, marginBottom: Spacing.lg }}>
              <TextLG style={{ marginLeft: Spacing.md }}>Workout Duration (Weekly)</TextLG>
              {vm.viewState.graphData?.length && vm.viewState.graphData?.length > 0 ? (
                <>
                  <FlexRow
                    style={{
                      alignItems: 'end',
                      gap: Spacing.sm,
                      marginLeft: Spacing.lg,
                      marginTop: Spacing.md,
                    }}
                  >
                    <DisplayXS>
                      {secondsToWordFormatMinutes(
                        vm.viewState.graphData[vm.viewState.graphData.length - 1].value,
                      ).toLocaleString()}
                    </DisplayXS>
                    <TextSMRegular type="secondary">This week</TextSMRegular>
                  </FlexRow>
                  <View style={{ height: 180 }}>
                    <ProfileBarChart data={vm.viewState.graphData} unit={'hr'} isWeekView={true} />
                  </View>
                </>
              ) : (
                <EmptyGraph title="No workouts in the last 3 months" />
              )}
            </Card>
          )}

          <MobileRoutinesContainer>
            {!!vm.viewState.routines?.length && (
              <MobileRoutineCard routines={vm.viewState.routines} />
            )}
          </MobileRoutinesContainer>

          {vm.numberOfWorkouts === 0 ? (
            <ZeroWorkouts username={vm.username} />
          ) : (
            <>
              <TextLG style={{ marginBottom: Spacing.lg }}>Workouts</TextLG>
              {vm.cells.map(c => {
                switch (c.type) {
                  case 'workout-cell':
                    return <WorkoutCell key={c.vm.workout.id} vm={c.vm} />;
                  case 'loading-cell':
                    return <LoadingCell key={c.key} />;
                }
              })}
              {vm.isFetchingMore && (
                <PagingLoadingContainer>
                  <ClipLoader size={30} color={colors.primary500} loading={true} />
                </PagingLoadingContainer>
              )}
              <div
                ref={bottomOfWorkoutsRef}
                style={vm.isFetchingMore || vm.isInitialFetch ? { display: 'none' } : {}}
              />
            </>
          )}
        </LeftContainer>
        <RightContainer>
          {!!vm.viewState.routines?.length && <RoutinesCard routines={vm.viewState.routines} />}
        </RightContainer>
      </Content>
    );
  }
};

const UserProfileScreenInner = observer(
  ({ vm, containerRef, bottomOfWorkoutsRef }: UserProfileScreenInnerProps) => {
    return (
      <Screen onScroll={vm.handleScroll} containerRef={containerRef}>
        <ProfileHeader
          username={vm.viewState.username}
          isOwner={false}
          fullname={vm.viewState.fullName}
          profilePic={vm.viewState.profilePic}
          imageUrls={vm.workoutImages}
          link={vm.viewState.link}
          workoutCount={vm.viewState.workoutCount}
          followerCount={vm.viewState.followerCount}
          onFollowersPress={
            vm.profileResult.type === 'authenticated' ? vm.handleFollowerClick : undefined
          }
          followingCount={vm.viewState.followingCount}
          onFollowingPress={
            vm.profileResult.type === 'authenticated' ? vm.handleFollowingsClick : undefined
          }
          description={vm.viewState.description}
          showFollowButton={vm.showFollowButton}
        />
        {renderProfileBody({ vm, bottomOfWorkoutsRef })}
      </Screen>
    );
  },
);
